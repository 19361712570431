import React, { useState, useContext } from 'react';
import { RichText } from 'prismic-reactjs';
import { 
  Section, 
  Container,
  Title,
  Description,
  Content,
  SelectArea, 
  SelectCard, 
  CheckBox, 
  Check,
  Input,
  Icon,
  Form,
  Label,
  FormTop,
  FormTopInput,
  FormBottom,
  FormAction
} from './styles';

import UxIcon from '../../../../assets/icons/contact/ux.svg';
import FrontendIcon from '../../../../assets/icons/contact/frontend.svg';
import BackendIcon from '../../../../assets/icons/contact/backend.svg';
import AppIcon from '../../../../assets/icons/contact/app.svg';
import MaintenanceIcon from '../../../../assets/icons/contact/maintenance.svg';
import OtherIcon from '../../../../assets/icons/contact/other.svg';
import CheckIcon from '../../../../assets/icons/contact/check.svg';

import DarkUxIcon from '../../../../assets/icons/contact/inverse/ux.svg';
import DarkFrontendIcon from '../../../../assets/icons/contact/inverse/frontend.svg';
import DarkBackendIcon from '../../../../assets/icons/contact/inverse/backend.svg';
import DarkAppIcon from '../../../../assets/icons/contact/inverse/app.svg';
import DarkMaintenanceIcon from '../../../../assets/icons/contact/inverse/maintenance.svg';
import DarkOtherIcon from '../../../../assets/icons/contact/inverse/other.svg';

import Button from '../../../UI/Button';
import InputField from '../../../UI/InputField';
import ThemeContext from '../../../../context/ThemeContext';

const ContactForm = ({ slice }) => {
  // Theme
  const { isDark } = useContext(ThemeContext);

  // Cards
  const selectCards = [
    {title: "User Experience", id: 'ux', icon: isDark ? DarkUxIcon : UxIcon},
    {title: "Frontend", id: 'web', icon: isDark ? DarkFrontendIcon : FrontendIcon},
    {title: "Backend", id: 'backend', icon: isDark ? DarkBackendIcon : BackendIcon},
    {title: "Mobile apps", id: 'apps', icon: isDark ? DarkAppIcon : AppIcon},
    {title: "Maintenance", id: 'maintenance', icon: isDark ? DarkMaintenanceIcon : MaintenanceIcon},
    {title: "Other request", id: 'other', icon: isDark ? DarkOtherIcon : OtherIcon}
  ];

  // State
  const [selected, setSelected] = useState({
    ux: false,
    web: false,
    backend: false,
    apps: false,
    maintenance: false,
    other: false
  });
  const [validInput, setValidInput] = useState({
    name: false,
    email: false,
    message: false
  });

  // Handle check
  const handleCheck = event => {
    const { name, checked } = event.target;
    setSelected({ ...selected, [name]: checked });
  };

  // Handle change
  const handleChange = event => {
    const { name, value } = event.target;
    setValidInput({ 
      ...validInput, 
      [name]: name === 'email' ? emailIsValid(value) : Boolean(value.length)
    });
  };

  // Email validation
  const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <Section>
      <Container>
        <Content data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
          <Title>
            {RichText.asText(slice.primary.contact_form_title.raw)}
          </Title>
          <Description>
            {RichText.asText(slice.primary.contact_form_text.raw)}
          </Description>
        </Content>
        <Form action="https://formspree.io/mgekkywa" method="POST">
          <SelectArea data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
            {selectCards.map((card, i) => (
              <SelectCard key={`area-${i}`} htmlFor={card.id} checked={selected[card.id]}>
                <CheckBox checked={selected[card.id]}>
                  {selected[card.id] && <Check src={CheckIcon} alt="checked" />}
                </CheckBox>
                <Icon src={card.icon} alt={`card-icon-${i}`} draggable="false" />
                <h4>{card.title}</h4>
                <Input
                  id={card.id}
                  name={card.id}
                  onChange={handleCheck}
                  type="checkbox" 
                />
              </SelectCard>
            ))}
          </SelectArea>
          <FormTop data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
            <FormTopInput>
              <Label htmlFor="name">
                {slice.primary.contact_form_name || 'Name'}
              </Label>
              <InputField
                id="name"
                placeholder={slice.primary.contact_form_name_placeholder || 'Your name'}
                onChange={handleChange}
                valid={validInput.name}
                required
              />
            </FormTopInput>
            <FormTopInput right>
              <Label htmlFor="email">
                {slice.primary.contact_form_email || 'Email'}
              </Label>
              <InputField
                id="email"
                type="email"
                placeholder={slice.primary.contact_form_email_placeholder || 'Your email'}
                onChange={handleChange}
                valid={validInput.email}
                required
              />
            </FormTopInput>
          </FormTop>
          <FormBottom data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
            <Label htmlFor="message">
              {slice.primary.contact_form_message || 'Message'}
            </Label>
            <InputField
              id="message"
              type="textarea"
              placeholder={slice.primary.contact_form_message_placeholder || 'Your message'}
              onChange={handleChange}
              valid={validInput.message}
              required
            />
          </FormBottom>
          <FormAction data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
            <Button
              type="submit" 
              text={slice.primary.contact_form_button || 'Send Message'}
            />
          </FormAction>
        </Form>
      </Container>
    </Section>
  );
};

export default ContactForm;
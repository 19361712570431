import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Subheader from '../components/page/SubHeader';
import ContactData from '../components/page/Contact/ContactData';
import ContactForm from '../components/page/Contact/ContactForm';

const Contact = ({ data }) => {
  // Props
  const contact = data.prismicContact.data;

  // Body
  const body = contact.body.map((slice, index) => {
    switch (slice.slice_type) {
      case ('contact_form'):
        return (
          <ContactForm 
            key={'slice-' + index} 
            slice={slice}
          />
        );
      case ('contact_data'):
        return (
          <ContactData 
            key={'slice-' + index} 
            items={slice.items} 
          />
        );
      default:
        return null;
    }
  })

  return (
    <Layout>
      <SEO 
        title={contact.meta_title}
        description={contact.meta_description}
        type='article'
      />
      <Subheader 
        title={contact.title}
        text={contact.description}
      />
      {body}
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query {
    prismicContact {
      data {
        body {
          ... on PrismicContactBodyContactForm {
            slice_type
            primary {
              contact_form_button
              contact_form_email
              contact_form_email_placeholder
              contact_form_message
              contact_form_message_placeholder
              contact_form_name
              contact_form_name_placeholder
              contact_form_text {
                raw
              }
              contact_form_title {
                raw
              }
            }
            items {
              contact_form_option_title {
                raw
              }
            }
          }
          ... on PrismicContactBodyContactData {
            slice_type
            items {
              contact_data_text {
                raw
              }
              contact_data_title {
                raw
              }
            }
          }
        }
        meta_description
        meta_title
        title {
          raw
        }
        description {
          raw
        }
      }
    }
  }
`;
import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../../../utils/styles';

export const Section = styled.section`
  background: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px){
    padding: 48px 24px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: ${dimensions.contentWidth};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.l}px){
    flex-direction: column;
    padding: 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  max-width: 400px;
  flex: 1;

  @media (max-width: ${breakpoints.l}px){
    margin-bottom: 24px;
  }

  :last-child {
    flex: 1.4;
  }

  p {
    margin-bottom: 0;
    color: ${({ theme }) => theme.textDark};
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.textDark};
`;
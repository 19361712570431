import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../../../utils/styles';

export const Section = styled.section`
  background: ${({ theme }) => theme.light};
  display: flex;
  justify-content: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px){
    padding: 48px 24px;
  }
`;

export const Container = styled.div`
  max-width: ${dimensions.contentWidth};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.textDark};
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.textDark};
`;

export const SelectArea = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.l}px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SelectCard = styled.label`
  position: relative;
  text-align: center;
  background: ${({ theme }) => theme.white};
  border: 4px solid ${({ checked, theme }) => checked ? theme.secondary : theme.white};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 8px;
  height: 124px;
  padding: 8px;

  @media (max-width: ${breakpoints.s}px){
    height: 132px;
  }

  h4 {
    color: ${({ theme }) => theme.textDark};
    user-select: none;
    margin-bottom: 0;
    font-weight: 500;
  }
`;

export const CheckBox = styled.div`
  background-color: ${({ checked, theme }) => checked ? theme.secondary : theme.light};
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  right: 8px;
  top: 8px;

  @media (max-width: ${breakpoints.l}px){
    margin: 8px;
  }
`;

export const Check = styled.img`
  margin-top: 6px;
`;

export const Input = styled.input`
  display: none;
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin: 20px 0 8px;
  user-select: none;

  @media (max-width: ${breakpoints.s}px){
    margin-top: 24px
  }
`;

export const Form = styled.form`
  width: 100%;
  margin-bottom: 0;
`;

export const Label = styled.label`
  display: flex;
  color: ${({ theme }) => theme.textDark};
  font-family: MuseoSans, sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const FormTop = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.s}px){
    flex-direction: column;
  }
`;

export const FormTopInput = styled.div`
  flex: 1;
  margin-bottom: 24px;
  margin-right: ${({ right }) => right ? '0' : '12px'};
  margin-left: ${({ right }) => right ? '12px' : '0'};

  @media (max-width: ${breakpoints.s}px){
    margin-right: 0;
    margin-left: 0;
  }
`;

export const FormBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const FormAction = styled.div`
  text-align: right;

  @media (max-width: ${breakpoints.s}px){
    text-align: center;
  }
`;

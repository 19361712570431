import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Section, Container, Column, Title } from './styles';

const ContactData = ({ items }) => {
  return (
    <Section>
      <Container data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
        {items.map((column, i) => (
          <Column key={`contact-col-${i}`}>
            <Title>
              {RichText.asText(column.contact_data_title.raw)}
            </Title>
            {RichText.render(column.contact_data_text.raw)}
          </Column>
        ))}
      </Container>
    </Section>
  );
};

export default ContactData;